import { UUID } from 'src/@types/common';

enum ColNames {
  Enrollments = 'enrollments',
  FlowDefs = 'flowDefinitions',
  Sessions = 'sessions',
  Workspaces = 'workspaces',
  FlowInstances = 'flowInstances',
  CanvasContent = 'canvasContent',
  CanvasWidget = 'canvasWidget',
  FocusManagerLocks = 'focusManagerLocks',
  SharedResources = 'sharedResources',
  SurveyAnswers = 'surveyAnswers',
}

function constructPath(...segments: Array<string | undefined>) {
  return segments.filter((segment) => !!segment).join('/');
}

export function getPathForFlowDefinitions(docId?: UUID) {
  return constructPath(ColNames.FlowDefs, docId);
}

/** @deprecated Use `getPathForFlowDefinitions` instead, as sessions are now part of the flow definition. */
export function getPathForSessions(flowId: UUID, docId?: UUID) {
  return constructPath(
    getPathForFlowDefinitions(flowId),
    ColNames.Sessions,
    docId,
  );
}

export function getPathForWorkspaces(docId?: UUID) {
  return constructPath(ColNames.Workspaces, docId);
}

export function getPathForFlowInstances(docId?: UUID) {
  return constructPath(ColNames.FlowInstances, docId);
}

export function getPathForCanvasContent(docId?: UUID) {
  return constructPath(ColNames.CanvasContent, docId);
}

export function getPathForCanvasWidget(docId?: UUID) {
  return constructPath(ColNames.CanvasWidget, docId);
}

export function getPathForEnrollments(docId?: UUID) {
  return constructPath(ColNames.Enrollments, docId);
}

export function getPathForFocusManagerLocks(docId?: UUID) {
  return constructPath(ColNames.FocusManagerLocks, docId);
}

export function getPathForSharedResources(docId?: UUID) {
  return constructPath(ColNames.SharedResources, docId);
}

export function getPathForSurveyAnswers(docId?: UUID) {
  return constructPath(ColNames.SurveyAnswers, docId);
}
