import { Provider as ModalProvider } from '@ebay/nice-modal-react';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import { useHydrateOnboardingEffect } from '@features/onboarding';
import Error500 from 'src/app/pages/Error500';
import { FlowContextProvider } from 'src/contexts/FlowContext';
import { useTrackPageTransitionsEffect } from 'src/services/analytics';
import { useActiveStoreInit } from 'src/state/active/hooks';
import { useCheckIfMobileEffect } from 'src/state/device';
import { isTouchDevice, isUserAgentTabletOrMobile } from 'src/utils/device';
import { useHandleFlowSwitch } from 'src/utils/flow/hooks';
import useGetAppLoadStateTuple, {
  AppLoadStatus,
} from './useGetAppLoadStateTuple';

const hasTouchBackend = isTouchDevice() && isUserAgentTabletOrMobile();

/*
 Flow context lifted because of the following uses:
  - Admin panel PDF generation
  - Avoiding giving lots of props to modal components due to them being separate from the main flow
*/

function App() {
  useActiveStoreInit();
  useHandleFlowSwitch();
  useTrackPageTransitionsEffect();
  useCheckIfMobileEffect();
  useHydrateOnboardingEffect();

  const [canRender, setCanRender] = useState(false);
  const [userStatus, workspaceStatus] = useGetAppLoadStateTuple();
  useEffect(() => {
    if (
      userStatus === AppLoadStatus.Failed ||
      (userStatus === AppLoadStatus.Success &&
        workspaceStatus === AppLoadStatus.Success)
    ) {
      setCanRender(true); // Set only once to prevent root-level transient re-renders on auth change
    }
  }, [userStatus, workspaceStatus]);

  // TODO Loading state

  return canRender ? (
    <FlowContextProvider>
      <ModalProvider>
        <DndProvider backend={hasTouchBackend ? TouchBackend : HTML5Backend}>
          <Helmet defaultTitle="Superflow" titleTemplate="%s | Superflow" />
          <Outlet />
        </DndProvider>
      </ModalProvider>
    </FlowContextProvider>
  ) : workspaceStatus === AppLoadStatus.Failed ? (
    <Error500 message="Unable to load the app." />
  ) : null;
}

export default App;
