import { useParams } from 'react-router-dom';
import { ParamsFromPath, PathParams } from 'src/app/router/routeValidation';
import { Path } from './routes';

/**
 * Returns all typed URL parameters.
 */
export function useTypedParams() {
  return useParams() as PathParams;
}

/**
 * @template TPath - A valid `Path`
 * @returns Typed path params of a single {@link Path}
 */
export function useTypedPathParams<TPath extends Path>() {
  return useParams() as unknown as ParamsFromPath<TPath>;
}
