import { IsEnum, IsOptional, IsString, MinLength } from 'class-validator';
import { WorkspaceTerminology } from 'src/services/database/Workspaces/dto/workspace.dto';

export class WorkspaceUpdateDto {
  @MinLength(2)
  @IsOptional()
  slug?: string;

  @MinLength(1)
  @IsOptional()
  name?: string;

  @IsEnum(WorkspaceTerminology)
  @IsOptional()
  terminology?: WorkspaceTerminology;

  @IsString()
  @IsOptional()
  logo?: string;

  constructor(dto: WorkspaceUpdateDto) {
    this.slug = dto.slug;
    this.name = dto.name;
    this.terminology = dto.terminology;
    this.logo = dto.logo;
  }
}
