import {
  QueryConstraint,
  collection,
  doc,
  documentId,
  getFirestore,
  orderBy,
  query,
  where,
} from '@firebase/firestore';
import { useMemo, useRef, useSyncExternalStore } from 'react';
import { UUID } from 'src/@types/common';
import { getPathForCanvasWidget } from '../paths';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
  UnknownPartial,
} from '../types';
import { useFirestoreQuerySubscription } from '../useFirestoreQuerySubscription';
import { useMemoizedQueryArgs } from '../utils';
import { getCanvasWidgetConverter } from './converter';
import { CanvasWidgetDto } from './dto/canvasWidget.dto';

/**
 * @param docId - The canvas widget ID
 * @returns Firestore reference to be used as a pointer in e.g. `getDoc()`
 */
export function getCanvasWidgetDocRef<TContent extends UnknownPartial>(
  docId: UUID,
) {
  const converter = getCanvasWidgetConverter<TContent>();
  return doc(getFirestore(), getPathForCanvasWidget(docId)).withConverter(
    converter,
  );
}

/**
 * @template Content - Specifies the unique data shape based on the canvas widget's type
 * (e.g. drop panels store different data than scoring grids).
 * @param queryArg - If not specified, every item will be returned from the collection.
 * Otherwise filtering can be performed by ID and/or object keys, and sorting can also be applied.
 * @returns Firestore collection reference.
 */
export function useCanvasWidgetCol<
  Content extends UnknownPartial = UnknownPartial,
>(queryArg?: CollectionQueryArgs<Partial<CanvasWidgetDto<Partial<Content>>>>) {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);

  const filterQuery = useMemo(() => {
    const constraints: QueryConstraint[] = [];

    if (filterByIds && filterByIds.length > 0) {
      // Firebase throws when filtering for 'in' with an empty array
      constraints.push(where(documentId(), 'in', filterByIds));
    }
    if (filterBy) {
      filterBy
        .filter(
          function noUndefinedFilterCriteria(
            condition,
          ): condition is FirestoreFilterArg<
            Partial<CanvasWidgetDto<Partial<Content>>>
          > {
            return condition?.value !== undefined;
          },
        )
        .forEach((condition) => {
          constraints.push(where(condition.key, condition.op, condition.value));
        });
    }
    if (sort) {
      constraints.push(orderBy(sort.key, sort.order));
    }
    return query(
      collection(getFirestore(), getPathForCanvasWidget()),
      ...constraints,
    );
  }, [filterBy, filterByIds, sort]);

  const converter = useRef(getCanvasWidgetConverter<Content>());
  const store = useFirestoreQuerySubscription(converter.current, filterQuery);
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(() => ({ result, error: store.error }), [result, store.error]);
}
