import { useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import {
  useFlowDefinitionId,
  useFlowInstanceId,
  useSessionId,
  useWorkspaceId,
} from 'src/utils/resource.hooks';
import { PathParams } from './routeValidation';
import { Path, buildPath } from './routes';

/**
 * A type-safe implementation of the `useNavigate` hook from `react-router-dom`.
 * It automatically fills in the workspace and flow slugs, and the flow instance ID if they are not provided.
 * @returns A type-safe implementation of the `useNavigate` hook from `react-router-dom`
 */
export default function useTypedNavigate(autoFill: boolean = true) {
  const navigate = useNavigate();
  const workspaceId = useWorkspaceId();
  const flowDefinitionId = useFlowDefinitionId();
  const flowInstanceId = useFlowInstanceId();
  const sessionId = useSessionId();

  return useCallback(
    function typedNavigate(
      to: Path,
      pathParams: PathParams = {},
      options?: NavigateOptions,
    ) {
      if (autoFill) {
        pathParams.workspaceId ??= workspaceId;
        pathParams.flowDefinitionId ??= flowDefinitionId;
        pathParams.flowInstanceId ??= flowInstanceId;
        pathParams.sessionId ??= sessionId;
      }

      const url = buildPath(to, pathParams);
      return navigate(url, options);
    },
    [
      navigate,
      workspaceId,
      flowDefinitionId,
      flowInstanceId,
      sessionId,
      autoFill,
    ],
  );
}
