/**
 * This enum should list all valid paths of the app.
 * Typed router components and hooks, such as `TypedLink`,
 * validate nav targets and path params against these paths.
 */
export enum Path {
  Home = '',
  Auth = '/auth',
  GuestSignup = '/guest-signup',
  ActionHandlerAction = '/action-handler/action',
  NotFound = '/404',
  WorkspaceAuth = '/workspace/auth',
  Workspace = '/workspace/:workspaceId',
  WorkspaceRecentFlows = '/workspace/:workspaceId/recent-flows',
  Flow = '/workspace/:workspaceId/flow/:flowDefinitionId',
  FlowHistory = '/workspace/:workspaceId/flow/:flowDefinitionId/history',
  FlowInstance = '/workspace/:workspaceId/flow/:flowDefinitionId/instance/:flowInstanceId',
  Session = '/workspace/:workspaceId/flow/:flowDefinitionId/instance/:flowInstanceId/session/:sessionId',

  FlowEditorHome = '/flow-editor',
  FlowVersionEditor = '/flow-editor/:flowProjectId',
  FlowVersionSessionBundles = '/flow-editor/:flowProjectId/session-bundles',
  FlowVersionModules = '/flow-editor/:flowProjectId/modules',
  FlowVersionSettings = '/flow-editor/:flowProjectId/settings',

  EmbeddedFlowInstance = '/candidate-invite/:shareId',
  EmbeddedSession = '/candidate-invite/:shareId/session/:sessionId',

  SessionEditor = '/flow-editor/:flowProjectId/session-editor/:sessionBundleId/:sessionVersionId',
  SessionEditorPreview = '/flow-editor/:flowProjectId/session-editor/:sessionBundleId/:sessionVersionId/preview',

  Admin = '/admin',
}

export function buildPath(
  path: Path,
  params?: Record<string, string | number>,
) {
  let url = path.startsWith('/') ? path : `/${path}`;
  if (params && typeof params === 'object') {
    Object.keys(params).forEach((key) => {
      url = url.replaceAll(`:${key}`, (params[key] as string) ?? '');
    });
  }

  return url;
}
