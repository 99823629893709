/**
 * This tag is used to categorize flow definitions. It's used by workspaces to
 * filter and search for flow definitions, so we can skip tight coupling between
 * workspaces and flow definitions based on the workspaces' terminology.
 *
 * If the enum values are updated, the corresponding Firestore security rules
 * should also be changed accordingly.
 */
export enum FlowDefinitionTag {
  LearningAndDevelopment = 'rnd',
  HumanResources = 'hr',
}
