import { UnknownPartial } from 'src/services/database/types';
import { _CanvasItemConfigBaseDto } from './_canvasItemBase.dto';

/**
 * Existing canvas notes added in the "editor" (e.g. canvas notes)
 */
export class CanvasDefaultContentDto<
  TContent = UnknownPartial,
> extends _CanvasItemConfigBaseDto<TContent> {
  constructor(dto: CanvasDefaultContentDto<TContent>) {
    super(dto);
  }
}
