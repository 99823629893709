import { Helmet } from 'react-helmet-async';
import { typography } from 'src/styles/typography.css';
import { Path } from 'src/app/router/routes';
import { Emoji } from '../components/shared/Emoji';
import { Flex } from '../components/shared/Flex';
import { TypedLink } from '../router';

export default function Error404() {
  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <Flex
        direction="column"
        style={{
          width: '100vw',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <h1 className={typography.h2} style={{ marginBottom: '1em' }}>
          4<Emoji title="zero">😢</Emoji>4
        </h1>
        <p>There&apos;s nothing here.</p>
        <TypedLink to={Path.Home}>Return to homepage</TypedLink>
      </Flex>
    </>
  );
}
