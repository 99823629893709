import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import { useEnsureWorkspace } from 'src/services/database/Workspaces/setters';
import { trace } from 'src/services/telemetry';
import { useSetUser, useUserId } from 'src/utils/userContent/hooks';

export enum AppLoadStatus {
  Initial,
  Processing,
  Success,
  Failed,
}

/**
 * The hook is responsible for fetching a user from Firebase, and retrieve the
 * workspace. If there is a valid user (guest or registered), a valid workspace
 * will be ensured via the `useEnsureWorkspace` hook.
 *
 * The hook should be called at the app's top-level component.
 * @returns The status of the base user data fetching process status.
 * @see {@link AppLoadStatus}
 * @see {@link useEnsureWorkspace}
 */
export default function useGetAppLoadStateTuple() {
  const userId = useUserId();
  const setUser = useSetUser();
  const ensureWorkspace = useEnsureWorkspace();
  const [userStatus, setUserStatus] = useState(AppLoadStatus.Initial);
  const [workspaceStatus, setWorkspaceStatus] = useState(AppLoadStatus.Initial);
  const [ensuredWorkspaceForUserId, setEnsuredWorkspaceForUserId] = useState<
    string | undefined
  >(undefined);

  useEffect(
    function waitForUser() {
      if (userStatus === AppLoadStatus.Initial) {
        setUserStatus(AppLoadStatus.Processing);
      }

      return onAuthStateChanged(getAuth(), (firebaseUser) => {
        if (firebaseUser?.uid) {
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
            isAnonymous: firebaseUser.isAnonymous,
          });

          setUserStatus(AppLoadStatus.Success);
        } else {
          setUserStatus(AppLoadStatus.Failed);
        }
      });
    },
    [setUser, userStatus],
  );

  // Ensure workspace for the user. This is used to assign the user to a workspace,
  // if the user is not already a member of the workspace, or to create a new workspace.
  // Checking if the workspace is already ensured for the user is done to avoid
  // infinite renders, as the auth state change listener will trigger frequently.
  useEffect(
    function ensureWorkspaceEffect() {
      if (userStatus !== AppLoadStatus.Success || !userId) {
        return;
      }

      if (userId === ensuredWorkspaceForUserId) {
        return;
      }

      if (workspaceStatus === AppLoadStatus.Processing) {
        return;
      }

      setEnsuredWorkspaceForUserId(userId);
      setWorkspaceStatus(AppLoadStatus.Processing);

      ensureWorkspace()
        .then(() => {
          setWorkspaceStatus(AppLoadStatus.Success);
        })
        .catch((error) => {
          trace(error);
          setWorkspaceStatus(AppLoadStatus.Failed);
        });
    },
    [
      userId,
      ensureWorkspace,
      ensuredWorkspaceForUserId,
      setEnsuredWorkspaceForUserId,
      userStatus,
      workspaceStatus,
    ],
  );

  // Using memo to avoid re-rendering the app component when the status does not
  // change due to returning a new array reference.
  return useMemo(
    () => [userStatus, workspaceStatus] as const,
    [userStatus, workspaceStatus],
  );
}
