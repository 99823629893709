import { useEffect, useMemo, useRef } from 'react';
import { UUID } from 'src/@types/common';
import { useEnrollmentCol } from 'src/services/database/Enrollments';
import { EnrollmentStatus } from 'src/services/database/Enrollments/utils';
import {
  useFlowDefinitionCol,
  useFlowDefinitionDoc,
} from 'src/services/database/FlowDefinitions/FlowDefinitions';
import { FLOW_DEFINITION_GLOBAL_WORKSPACE_ID } from 'src/services/database/FlowDefinitions/constants';
import { FlowDefinitionDto } from 'src/services/database/FlowDefinitions/dto/flowDefinition.dto';
import { FlowInstanceStatus } from 'src/services/database/FlowInstances/flowInstanceStatus';
import { useFlowInstanceDoc } from 'src/services/database/FlowInstances/getters';
import { useWorkspace } from 'src/services/database/Workspaces/getters';
import { CollectionQueryArgs } from 'src/services/database/types';
import { buildSimpleQuery } from 'src/services/database/utils';
import { useResetConfettiState } from 'src/state/flow/completion';
import { useLocalized } from 'src/utils/i18n';
import {
  useFlowDefinitionId,
  useFlowInstanceId,
} from 'src/utils/resource.hooks';
import { useUserId } from 'src/utils/userContent/hooks';

interface IUserFlowDefinitionListOptions {
  doesAllowGlobal?: boolean;
}

/**
 * Retrieves a list of flow definitions, based on the current workspace, and its
 * tags. If no workspace is available, it will return the global flow definitions.
 * If the `allowGlobal` flag is set and no workspace is available, it will set
 * the workspace ID to empty, therefore not returning any flow definitions.
 *
 * @param options.doesAllowGlobal - If true, it will return global flow
 * definitions, otherwise it will only return workspace flow definitions.
 */
export function useFlowDefinitionList(
  options: IUserFlowDefinitionListOptions = {},
) {
  const workspace = useWorkspace();
  const { doesAllowGlobal = true } = options;
  const query = useMemo<CollectionQueryArgs<Partial<FlowDefinitionDto>>>(() => {
    if (!workspace) {
      return {
        filterBy: [
          {
            key: 'workspaceId',
            op: '==',
            value: doesAllowGlobal ? FLOW_DEFINITION_GLOBAL_WORKSPACE_ID : '',
          },
        ],
      };
    }

    return {
      filterBy: [
        {
          key: 'workspaceId',
          op: doesAllowGlobal ? 'in' : '==',
          value: doesAllowGlobal
            ? [workspace.id, FLOW_DEFINITION_GLOBAL_WORKSPACE_ID]
            : workspace.id,
        },
        {
          key: 'tags',
          op: 'array-contains-any',
          value: workspace.tags,
        },
      ],
    };
  }, [doesAllowGlobal, workspace]);

  return useFlowDefinitionCol(query);
}

/**
 * Tries retrieving the definition for the current flow.
 *
 * At least one of the following must be present:
 * - the flow definition ID as a URL path param
 * - the flow instance ID as a URL path param,
 * because the flow instance also contains a definition reference.
 *
 * **If** the workspace ID is available in the URL's path param,
 * the hook also checks the workspace ID and tags against the flow definition
 * as a security measure.
 */
export function useFlowDefinition() {
  const resource = useFlowDefinitionDoc(useFlowDefinitionId());
  const workspace = useWorkspace();

  return useMemo(() => {
    const data = resource.result?.data();

    if (workspace && data) {
      const hasMatchingTags = workspace?.tags.some((tag) =>
        data.tags.includes(tag),
      );

      // TODO [security] replace with Firestore rules
      // If the flow definition is not in the current workspace, and it's not a global flow,
      // we don't want to show it.
      if (
        !hasMatchingTags ||
        (data.workspaceId !== workspace?.id &&
          data.workspaceId !== FLOW_DEFINITION_GLOBAL_WORKSPACE_ID)
      ) {
        return;
      }
    }

    return data;
  }, [resource.result, workspace]);
}

/**
 * Retrieves the flow instance for the current flow.
 */
export function useFlowInstance() {
  const flowInstanceId = useFlowInstanceId();
  return useFlowInstanceDoc(flowInstanceId)?.result?.data();
}

export function useCanFlowInstanceBeMarkedAsCompleted() {
  const flowInstance = useFlowInstance();
  const creatorId = useUserId();
  const flowInstanceId = useFlowInstanceId();
  const query = useMemo(
    () =>
      buildSimpleQuery({
        creatorId,
        flowInstanceId,
      }),
    [creatorId, flowInstanceId],
  );

  const enrollmentsFound = useEnrollmentCol(query);

  if (flowInstance?.status === FlowInstanceStatus.Completed) {
    return false;
  }

  return enrollmentsFound.result?.docs?.some(
    (x) => x.data().status === EnrollmentStatus.Completed,
  );
}

/**
 * Retrieves the title of a flow definition.
 */
export function useFlowDefinitionTitle() {
  const flow = useFlowDefinition();
  return useLocalized(flow?.title, '');
}

/**
 * Performs any necessary cleanup tasks when the user is
 * switching between flows, while staying in the flow view.
 */
export function useHandleFlowSwitch() {
  const flowDefinitionId = useFlowDefinitionId();
  const previousFlowDefinitionId = useRef<UUID>();
  const resetConfettiState = useResetConfettiState();
  useEffect(
    function handleFlowSwitch() {
      if (
        flowDefinitionId &&
        flowDefinitionId !== previousFlowDefinitionId.current
      ) {
        resetConfettiState();
        previousFlowDefinitionId.current = flowDefinitionId;
      }
    },
    [resetConfettiState, flowDefinitionId],
  );
}

export enum FlowDefinitionLandingPageType {
  Regular = 'regular',
  Video = 'video',
  Image = 'image',
}

const flowDefinitionLandingPageVideoExtensions = new Set(['mp4', 'webm']);
const flowDefinitionLandingPageImageExtensions = new Set([
  'jpg',
  'jpeg',
  'png',
  'gif',
  'svg',
]);
const flowDefinitionLandingPageTypeTest = /(?:\.([^.]+))?$/;

export function useFlowDefinitionLandingPageType(
  argFlowDefinition?: FlowDefinitionDto,
): FlowDefinitionLandingPageType | undefined {
  const retrievedFlowDefinition = useFlowDefinition();
  const flow = argFlowDefinition ?? retrievedFlowDefinition;

  return useMemo(() => {
    const url = flow?.landingPageUrl;
    if (!url) {
      return;
    }

    const fileType = url
      ? flowDefinitionLandingPageTypeTest.exec(url)?.[1]
      : undefined;
    if (fileType) {
      if (flowDefinitionLandingPageVideoExtensions.has(fileType)) {
        return FlowDefinitionLandingPageType.Video;
      } else if (flowDefinitionLandingPageImageExtensions.has(fileType)) {
        return FlowDefinitionLandingPageType.Image;
      }
    }

    return FlowDefinitionLandingPageType.Regular;
  }, [flow?.landingPageUrl]);
}
