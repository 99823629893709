import {
  FirestoreError,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  setDoc,
  where,
  writeBatch,
} from '@firebase/firestore';
import { v4 } from 'uuid';
import { UUID } from 'src/@types/common';
import { trace } from 'src/services/telemetry';
import { getPathForCanvasContent } from '../paths';
import { UnknownPartial } from '../types';
import {
  CanvasContent,
  CanvasContentConverter,
  CanvasContentPayload,
  CanvasContentToDb,
} from './utils';

/**
 * @param docId - The canvas content ID
 * @returns Firestore reference to be used as a pointer in e.g. `getDoc()`
 */
export function getCanvasContentDocRef<TContent = UnknownPartial>(docId: UUID) {
  const converter = CanvasContentConverter<TContent>();
  return doc(getFirestore(), getPathForCanvasContent(docId)).withConverter(
    converter,
  );
}

/**
 * Creates a new canvas content document in Firestore, and returns a reference to the document.
 * @param content - A complete (not partial) canvas content payload.
 * @param handMadeContentId - Content ID should be randomized by Firestore. But this optional arg is available
 * to support canvas tables of existing flows, whose IDs are composed by hand. The reasons are:
 * canvas tables are "singletons" within the canvas, and they should always exist, even in empty canvases.
 * @returns A document reference where e.g. the new item's ID can be accessed.
 */
export async function createCanvasContentDoc<Content = UnknownPartial>(
  // TODO omit timestamp props after obsolete src/context/CanvasContentProvider hooks are removed.
  // Until then, those hooks would just get more complicated due to the legacy canvas.
  content: Omit<CanvasContentToDb<Content>, 'id'>,
  handMadeContentId?: UUID,
) {
  try {
    const docId = handMadeContentId ?? v4();
    const convertedContent: CanvasContentToDb<Content> = {
      ...content,
      id: docId,
      createdAt: serverTimestamp(),
      editedAt: serverTimestamp(),
      lastMovedAt: serverTimestamp(),
    };
    const ref = getCanvasContentDocRef<Content>(docId);
    await setDoc(ref, convertedContent);
    return ref;
  } catch (error) {
    if (error instanceof Error || error instanceof FirestoreError) {
      trace(error);
    } else {
      trace(String(error));
      throw error;
    }
  }
}

/**
 * @param itemId - ID of the document being updated.
 * @param partialCanvasContent - Partial canvas content payload to update.
 */
export async function updateCanvasContentDoc<Content = UnknownPartial>(
  itemId: UUID,
  partialCanvasContent: Partial<CanvasContentPayload<Partial<Content>>>,
) {
  const converter = CanvasContentConverter<Content>();

  try {
    return await setDoc(
      doc(getFirestore(), getPathForCanvasContent(itemId)).withConverter(
        converter,
      ),
      partialCanvasContent,
      { merge: true },
    );
  } catch (error) {
    if (error instanceof Error || error instanceof FirestoreError) {
      trace(error);
    } else {
      trace(String(error));
      throw error;
    }
  }
}

/**
 * Bulk deletes one or more canvas content documents from Firestore in a single transaction.
 *
 * If a canvas item is referred in an outcome item, then the outcome item is also deleted.
 * @param itemIds - `"all"` or a list of item IDs whose content should be deleted.
 */
export async function deleteCanvasContentCol(
  flowInstanceId: UUID,
  sessionId: UUID,
  itemIds: 'all' | UUID[],
) {
  try {
    const deleteBatch = writeBatch(getFirestore());
    const contentColRef = collection(getFirestore(), getPathForCanvasContent());

    if (itemIds === 'all') {
      const flowInstanceKey: keyof CanvasContent = 'flowInstanceId';
      const sessionKey: keyof CanvasContent = 'sessionId';
      const contentRefWithinSession = query(
        collection(getFirestore(), getPathForCanvasContent()),
        where(flowInstanceKey, '==', flowInstanceId),
        where(sessionKey, '==', sessionId),
      );
      const allContentDocs = await getDocs(contentRefWithinSession);
      allContentDocs.forEach((contentDoc) => {
        deleteBatch.delete(contentDoc.ref);
      });
    } else {
      for (const itemId of itemIds) {
        const contentDoc = doc(contentColRef, itemId);
        deleteBatch.delete(contentDoc);
      }
    }
    await deleteBatch.commit();
  } catch (error) {
    trace(
      error instanceof Error || error instanceof FirestoreError
        ? error
        : String(error),
    );
    throw error;
  }
}
