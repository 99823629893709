import {
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryConstraint,
  QuerySnapshot,
  SetOptions,
  Timestamp,
  WithFieldValue,
  collection,
  doc,
  documentId,
  getDoc,
  getFirestore,
  orderBy,
  query,
  where,
} from '@firebase/firestore';
import { ValidatorOptions, isObject, validateSync } from 'class-validator';
import { produce } from 'immer';
import isEqual from 'lodash.isequal';
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore,
} from 'react';
import { UUID } from 'src/@types/common';
import { useFirestoreDocSubscription } from 'src/services/database/useFirestoreDocSubscription';
import { useFirestoreQuerySubscription } from 'src/services/database/useFirestoreQuerySubscription';
import { cloneObject } from 'src/utils/object';
import { Resource } from 'src/utils/resource';
import { trace } from '../telemetry';
import {
  CollectionQueryArgs,
  FirestoreFilterArg,
  UnknownPartial,
} from './types';

/**
 * This is a util hook that splits up a {@link CollectionQueryArgs} object,
 * and memoizes the nested objects.
 *
 * This prevents infinite re-renders caused by the argument object's reference changes,
 * and consumers don't need to memoize the config object themselves
 * (which would likely be forgotten).
 * @param queryArg - Config for sorting and filtering criteria
 * @returns Memoized config objects for sorting and filtering
 */
export function useMemoizedQueryArgs<T = unknown>(
  queryArg?: CollectionQueryArgs<T>,
): CollectionQueryArgs<T> {
  const { filterBy, filterByIds, sort } = queryArg ?? {};
  const [actualFilterBy, setActualFilterBy] =
    useState<CollectionQueryArgs<T>['filterBy']>(filterBy);
  const [actualSortBy, setActualSortBy] =
    useState<CollectionQueryArgs<T>['sort']>(sort);
  const [actualFilterByIds, setActualFilterByIds] =
    useState<CollectionQueryArgs<T>['filterByIds']>(filterByIds);

  useEffect(() => {
    if (!isEqual(filterByIds, actualFilterByIds)) {
      setActualFilterByIds(filterByIds);
    }
    if (!isEqual(sort, actualSortBy)) {
      setActualSortBy(sort);
    }
    if (
      filterBy?.length !== actualFilterBy?.length ||
      (filterBy &&
        actualFilterBy &&
        !filterBy.every((item, ix) => isEqual(item, actualFilterBy[ix])))
    ) {
      setActualFilterBy(filterBy);
    }
  }, [
    actualFilterBy,
    actualFilterByIds,
    actualSortBy,
    filterBy,
    filterByIds,
    sort,
  ]);

  return {
    filterBy: actualFilterBy,
    filterByIds: actualFilterByIds,
    sort: actualSortBy,
  };
}

/**
 * Reduces the duplicate logic of defining optional query arguments for Firestore queries.
 *
 * Although the example below shows a hook implementation, this function can be used in any context.
 * @param queryArg - Internal query configuration used by the UI. See {@link CollectionQueryArgs}.
 * @returns Query configuration converted to an array of Firestore constraints
 * that can be used in document and collection references.
 * @example
 * ```ts
 * function useSampleDataFetcher(itemId: UUID, query: CollectionQueryArgs<SampleItemType>) {
 *   // Memoize query args to prevent infinite re-renders
 *   const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
 *   // Memoize query to prevent infinite re-renders
 *   const memoizedQuery = useMemo(() =>
 *     query(
 *       collection(getFirestore(), 'path/to/resource'),
 *       // Convert query arguments to Firestore's format
 *       ...composeQueryConstraints<SampleItemType>({ filterBy, filterByIds, sort})
 *     )
 *   , [itemId, filterBy, filterByIds, sort])
 *   // ...
 * }
 * ```
 */
export function composeQueryConstraints<T = unknown>(
  queryArg: CollectionQueryArgs<T>,
): QueryConstraint[] {
  const { filterBy, filterByIds, sort } = queryArg;
  const constraints: QueryConstraint[] = [];
  if (filterByIds && filterByIds.length > 0) {
    // Firebase throws when filtering for 'in' with an empty array
    constraints.push(where(documentId(), 'in', filterByIds));
  }
  if (filterBy) {
    filterBy
      .filter(
        function noUndefinedFilterCriteria(
          condition,
        ): condition is FirestoreFilterArg<T> {
          return condition?.value !== undefined;
        },
      )
      .forEach((condition) => {
        condition.key;
        constraints.push(
          where(String(condition.key), condition.op, condition.value),
        );
      });
  }
  if (sort) {
    constraints.push(orderBy(String(sort.key), sort.order));
  }
  return constraints;
}

/**
 * Removes undefined values from an object. Used to sanitize data before sending to Firestore to prevent Firestore errors.
 * @param data - Data object to sanitize.
 * @returns Data object with undefined values removed.
 */
export function ensureNoUndefinedInFirestoreData<T extends UnknownPartial>(
  data: T,
): T {
  return produce(data, (draft) => {
    const removeUndefined = (obj: unknown) => {
      if (!isObject(obj)) return;
      Object.keys(obj).forEach((key) => {
        const typedKey = key as keyof typeof obj;
        if (obj[typedKey] === undefined) {
          delete obj[typedKey];
        } else if (Array.isArray(obj[typedKey])) {
          const arr = obj[typedKey] as unknown[];
          arr.map((item) => removeUndefined(item));
        } else if (isObject(obj[typedKey])) {
          removeUndefined(obj[typedKey]);
        }
      });
    };

    removeUndefined(draft);
  });
}

/**
 * Retrieves a document from Firestore.
 * Unless necessary for a specific use case, subscriptions are preferred over awaited document getters.
 * @param path - Path to the document in Firestore.
 * @param converter - Firestore data converter to convert data to/from Firestore.
 */
export async function getFirebaseDocWithConverter<
  TAppModelType extends DocumentData,
  TDBModelType extends DocumentData,
>(
  path: string,
  converter: FirestoreDataConverter<TAppModelType, TDBModelType>,
) {
  return getDoc(doc(getFirestore(), path).withConverter(converter));
}

type ConverterToData<TAppModelType> =
  | PartialWithFieldValue<TAppModelType>
  | WithFieldValue<TAppModelType>;

/**
 * Creates a Firestore data converter, facilitating the conversion and validation of data between Firestore and your application.
 *
 * Firestore data converters are used to streamline the process of converting Firestore documents to application models and vice versa,
 * reducing boilerplate code and ensuring data integrity.
 * @template TAppModelType - App model type.
 * @template TDBModelType - Firestore model type. Defaults to app model type.
 * @param assertFromFirebase - Function to assert that the data from Firestore is valid.
 * @param assertToFirebase - Function to assert that the data to Firestore is valid.
 * @param convertFromFirebase - Function to convert Firestore data to app data. (Optional)
 * @param convertToFirebase - Function to convert app data to Firestore data. (Optional)
 * @example
 * ```ts
 * const userConverter = createConverter<UserData, UserFirebaseData>(
 *  function assertFromFirebase(data) {
 *    if (!data.firebaseId) {
 *      throw new Error('Firebase ID isn't provided');
 *    }
 *  },
 *  function assertToFirebase(data) {
 *    if (!data.email) {
 *      throw new Error('Email is required');
 *    }
 *  },
 *  function convertFromFirebase(data) {
 *    return {
 *      createdAt: data.createdAt.toDate(),
 *      email: data.email,
 *      name: data.name,
 *    };
 *  },
 *  function convertToFirebase(data) {
 *    return {
 *      createdAt: Timestamp.fromDate(data.createdAt),
 *      email: data.email,
 *      name: data.name,
 *    };
 *  },
 * );
 * ```
 */
export function createConverter<
  TAppModelType extends DocumentData,
  TDBModelType extends DocumentData = TAppModelType,
>(
  assertFromFirebase: (data: DocumentData) => asserts data is TDBModelType,
  assertToFirebase: (
    data: unknown,
    options: SetOptions,
  ) => asserts data is ConverterToData<TDBModelType>,
  convertFromFirebase?: (data: TDBModelType) => TAppModelType,
  convertToFirebase?: (
    data: ConverterToData<TAppModelType>,
    options: SetOptions,
  ) => ConverterToData<TDBModelType>,
): FirestoreDataConverter<TAppModelType, TDBModelType> {
  return {
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options);
      assertFromFirebase(data);
      return convertFromFirebase ? convertFromFirebase(data) : data;
    },

    toFirestore(data, options) {
      const dataToDb = convertToFirebase
        ? convertToFirebase(data, options)
        : data;
      assertToFirebase(dataToDb, options);
      return ensureNoUndefinedInFirestoreData(dataToDb);
    },
  } as FirestoreDataConverter<TAppModelType, TDBModelType>;
}

export type CreateValidatorConverterValidModel = DocumentData & {
  id: UUID;
  createdAt: number;
};

/**
 * Creates a Firestore data converter that validates data using class-validator.
 * @param model - Class model to validate data against.
 * @returns Firestore data converter.
 * @example
 * ```ts
 * const userConverter = createValidatorConverter(UserData);
 * ```
 */
export function createValidatorConverter<
  T extends CreateValidatorConverterValidModel,
>(model: new (data: T) => T): FirestoreDataConverter<T, Omit<T, 'id'>> {
  return {
    fromFirestore(snapshot, options) {
      const data = snapshot.data(options) as T;
      const dto = new model({
        ...data,
        id: snapshot.id,
        createdAt: (data.createdAt as unknown as Timestamp)?.toMillis(),
      });

      validateSyncAndThrow(dto, { skipMissingProperties: true });
      return dto;
    },
    toFirestore(data: T, options?: SetOptions) {
      const isPartialData =
        isObject(options) && ('merge' in options || 'mergeFields' in options);

      const dto = new model({
        ...data,
        id: data.id ?? 'temp-id',
      } as T);
      validateSyncAndThrow(dto, { skipMissingProperties: isPartialData });
      const cloned = produce<T, Omit<T, 'id'> & { createdAt?: Timestamp }>(
        cloneObject(data),
        (draft) => {
          delete draft.id;
          if (typeof draft.createdAt === 'number') {
            draft.createdAt = Timestamp.fromMillis(draft.createdAt);
          }
        },
      );

      return ensureNoUndefinedInFirestoreData(cloned);
    },
  };
}

/**
 * Calls class-validator's {@link validateSync}, and throws if validation fails.
 * Errors are listed in the console.
 * @param object - Unknown object to validate
 * @param args - Arguments passed to `validateSync()`. See {@link ValidatorOptions}.
 */
export function validateSyncAndThrow(
  object: object,
  args: ValidatorOptions = {},
) {
  const validationErrors = validateSync(object, args);

  if (validationErrors.length > 0) {
    const formattedErrors = validationErrors
      .map((error) => {
        const constraints = Object.values(
          Object.assign({}, error.constraints, error.children),
        ).join(', ');
        return `${error.property}: ${constraints}`;
      })
      .join('\n');

    trace({
      level: 'error',
      data: object,
    });
    trace({
      level: 'error',
      data: validationErrors,
    });
    throw new TypeError(`Validation failed: ${formattedErrors}`);
  }
}

/**
 * Traces Firestore errors after validating their type
 * (the project'ts TS config is set to treat catch block variables as `unknown`),
 * then re-throws the error.
 * @param error - The error caught in a catch block.
 * @param data - Optional map of parameters that help with debugging. These are rendered in Sentry reports.
 * @see https://docs.sentry.io/product/issues/issue-details/breadcrumbs/
 */
export function catchFirestoreError(error: unknown, data?: UnknownPartial) {
  if (data) {
    trace({
      level: 'error',
      category: 'firebase',
      data,
    });
  }
  if (error instanceof Error) {
    trace(error);
  } else {
    trace({
      category: 'firebase',
      level: 'error',
      message: String(error),
    });
  }
  throw error;
}

export type SimpleQueryFilters<T extends object> = {
  [key in keyof Partial<T>]: string | number | boolean;
};

export type SimpleQuerySort<T extends object> = {
  [key in keyof Partial<T>]: 'asc' | 'desc';
};

/**
 * Builds a simple Firestore query from a set of filters and an optional sort.
 * @param filters - Filters to apply to the query.
 * @param sort - Optional sort configuration.
 * @example
 * ```ts
 * const query = buildSimpleQuery({ name: 'John' }, { age: 'asc' });
 * ```
 */
export function buildSimpleQuery<T extends object>(
  filters: SimpleQueryFilters<T>,
  sort?: SimpleQuerySort<T>,
) {
  return {
    filterBy: Object.entries(filters)
      .filter(([, value]) => value !== undefined && value !== null)
      .map(([key, value]) => ({
        key,
        value,
        op: '==',
      })),
    sort: sort
      ? {
          key: Object.keys(sort)[0] as keyof T,
          order: Object.values(sort)[0],
        }
      : null,
  } as CollectionQueryArgs<T>;
}

/**
 * Subscribes to Firestore and returns a single document.
 * @param path - Path to the document in Firestore.
 * @param converter - Firestore data converter to convert data to/from Firestore.
 * @returns Firestore document reference.
 */
export function useDoc<
  TData extends DocumentData,
  TDataFromDb extends DocumentData = TData,
  TDataToDb extends DocumentData = TDataFromDb,
>(
  path: string | undefined,
  converter: FirestoreDataConverter<TData, TDataFromDb>,
) {
  const instanceQuery = useMemo(
    () => (path ? doc(getFirestore(), path) : undefined),
    [path],
  );
  const converterRef =
    useRef<FirestoreDataConverter<TData, TDataFromDb>>(converter);
  const store = useFirestoreDocSubscription<TData, TDataToDb>(
    converterRef.current as unknown as FirestoreDataConverter<TData, TDataToDb>,
    instanceQuery,
  );
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}

/**
 * Subscribes to Firestore and returns a collection of documents.
 * @param path - Path to the collection in Firestore.
 * @param converter - Firestore data converter to convert data to/from Firestore.
 * @param queryArg - Query argument that specifies which documents are returned.
 * @returns A Firestore collection reference.
 */
export function useCol<
  TData extends DocumentData,
  TDataFromDb extends DocumentData = TData,
  TDataToDb extends DocumentData = TDataFromDb,
>(
  path: string,
  converter: FirestoreDataConverter<TData, TDataFromDb>,
  queryArg: CollectionQueryArgs<TData>,
): Resource<QuerySnapshot<TData>> {
  const { filterBy, filterByIds, sort } = useMemoizedQueryArgs(queryArg);
  const queryMemo = useMemo(
    () =>
      query(
        collection(getFirestore(), path),
        ...composeQueryConstraints({ filterBy, filterByIds, sort }),
      ),
    [filterBy, filterByIds, path, sort],
  );

  const converterRef = useRef(converter);
  const store = useFirestoreQuerySubscription<TData, TDataToDb>(
    converterRef.current as unknown as FirestoreDataConverter<TData, TDataToDb>,
    queryMemo,
  );
  const result = useSyncExternalStore(store.subscribe, store.getSnapshot);
  return useMemo(
    () => ({
      result,
      error: store.error,
    }),
    [result, store.error],
  );
}
