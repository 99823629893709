import {
  IsArray,
  IsEnum,
  IsOptional,
  IsString,
  MinLength,
} from 'class-validator';
import { IsWorkspaceMemberRecords } from 'src/services/database/Workspaces/validators';
import { WorkspaceMemberDto } from 'src/services/database/Workspaces/dto/workspaceMember.dto';
import { WorkspaceTerminology } from 'src/services/database/Workspaces/dto/workspace.dto';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';

/**
 * Workspace Create DTO is used to create a new workspace and validate the input.
 */
export class WorkspaceCreateDto {
  @MinLength(2, {
    message: 'Name must be at least 2 characters long.',
  })
  readonly name: string;

  @IsEnum(WorkspaceTerminology)
  readonly terminology: WorkspaceTerminology;

  @MinLength(1, {
    message: 'Slug cannot be empty or undefined.',
  })
  readonly slug: string;

  @IsString()
  @IsOptional()
  readonly logo?: string;

  @IsWorkspaceMemberRecords()
  readonly members: Record<string, WorkspaceMemberDto>;

  @IsArray()
  @MinLength(1, { each: true })
  readonly tags: FlowDefinitionTag[];

  @MinLength(1)
  readonly createdBy: string;

  constructor(dto: WorkspaceCreateDto) {
    this.name = dto.name;
    this.terminology = dto.terminology;
    this.slug = dto.slug;
    this.logo = dto.logo;
    this.members = dto.members;
    this.tags = dto.tags;
    this.createdBy = dto.createdBy;
  }
}
