import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from '@firebase/firestore';
import { validateSync } from 'class-validator';
import { User as FirebaseUser } from 'firebase/auth';
import { useCallback } from 'react';
import { generateGuestUserDisplayName } from 'src/services/auth/utils';
import { FlowDefinitionTag } from 'src/services/database/flowDefinitionTag';
import { getPathForWorkspaces } from 'src/services/database/paths';
import { gatherValidationErrorConstraints } from 'src/services/database/validators/utils';
import { WorkspaceConverter } from 'src/services/database/Workspaces/converter';
import {
  WorkspaceDto,
  WorkspaceTerminology,
} from 'src/services/database/Workspaces/dto/workspace.dto';
import { WorkspaceCreateDto } from 'src/services/database/Workspaces/dto/workspaceCreate.dto';
import {
  WorkspaceMemberDto,
  WorkspaceMemberRole,
} from 'src/services/database/Workspaces/dto/workspaceMember.dto';
import { WorkspaceUpdateDto } from 'src/services/database/Workspaces/dto/workspaceUpdate.dto';
import { trace } from 'src/services/telemetry/trace';
import { User } from 'src/state/user/types';
import { cloneObject } from 'src/utils/object';
import { useWorkspaceId } from 'src/utils/resource.hooks';
import { useUser } from 'src/utils/userContent/hooks';

export function userToWorkspaceMember(
  user: User | FirebaseUser,
  role: WorkspaceMemberRole,
) {
  return new WorkspaceMemberDto({
    role,
    id: user.uid,
    displayName: user.displayName ?? generateGuestUserDisplayName(user.uid),
  });
}

/**
 * Creates a new workspace in Firestore.
 * Internal use only, use `useCreateWorkspace` instead.
 * @param dto - Workspace creation data
 * @param firestore - The Firestore instance. Defaults to the global Firestore instance.
 * @returns a promise that resolves with the newly created workspace
 * @throws `Error` if workspace validation fails or Firestore returns with an error
 * @internal
 * @see useCreateWorkspace
 */
export async function createWorkspace(
  dto: WorkspaceCreateDto,
  firestore: Firestore = getFirestore(),
): Promise<WorkspaceDto> {
  trace({
    message: 'Attempting to create a new workspace in Firestore.',
    level: 'info',
  });

  try {
    const errors = validateSync(new WorkspaceCreateDto(dto));
    if (errors.length > 0) {
      throw gatherValidationErrorConstraints(errors);
    }

    const workspace: Omit<WorkspaceDto, 'id'> = {
      ...dto,
      createdAt: Date.now(),
      logo: dto.logo ?? '',
    };

    const document = doc(
      collection(firestore, getPathForWorkspaces()),
      workspace.slug,
    ).withConverter(WorkspaceConverter);

    await setDoc(document, workspace);

    return new WorkspaceDto({
      ...workspace,
      id: workspace.slug,
    });
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

/**
 * Creates a callback that creates a new workspace in Firestore.
 * @returns a callback with a promise that resolves with the newly created workspace or throws an error
 */
export function useCreateWorkspace() {
  return useCallback((dto: WorkspaceCreateDto) => {
    return createWorkspace(dto);
  }, []);
}

/**
 * Update workspace in Firestore.
 * @param workspaceId - The workspace ID.
 * @param dto - The payload to update the workspace with, can be a partial workspace object.
 * @param partial - Whether to perform a partial update.
 * @returns A promise that resolves with void.
 */
async function updateWorkspace(
  workspaceId: string,
  dto: WorkspaceUpdateDto,
  partial: boolean = true,
): Promise<void> {
  trace({
    message: `Attempting to update workspace: ${workspaceId}`,
    data: dto,
    level: 'info',
  });

  try {
    const documentRef = doc(
      getFirestore(),
      getPathForWorkspaces(workspaceId),
    ).withConverter(WorkspaceConverter);
    const documentSnapshot = await getDoc(documentRef);
    if (!documentSnapshot.exists()) {
      throw new Error(`Workspace with slug ${workspaceId} was not found`);
    }

    await setDoc(documentRef, cloneObject(dto), { merge: partial });
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

``;

/**
 * Creates a callback that updates a workspace in Firestore.
 * @param workspaceId - The workspace ID.
 * @returns A callback with a promise that resolves with void or throws an error.
 */
export function usePartialUpdateWorkspace(workspaceId: string) {
  return useCallback(
    async (dto: WorkspaceUpdateDto) => {
      return updateWorkspace(workspaceId, dto, true);
    },
    [workspaceId],
  );
}

/**
 * Add member to the workspace.
 * Internal use only, use `useAddMemberToWorkspace` instead.
 * @param workspaceId - The workspace ID.
 * @param member - The new member object.
 * @param firestore - The Firestore instance. Defaults to the global Firestore instance.
 * @returns A callback with a promise that resolves with void or throws an error.
 * @throws `Error` if Firestore returns with an error.
 * @internal
 * @see useAddMemberToWorkspace
 */
export async function addMemberToWorkspace(
  workspaceId: string,
  member: WorkspaceMemberDto,
  firestore?: Firestore,
): Promise<void> {
  trace({
    message: `Adding member to workspace: ${workspaceId}`,
    data: member,
    level: 'info',
  });

  try {
    const documentRef = doc(
      firestore ?? getFirestore(),
      getPathForWorkspaces(workspaceId),
    ).withConverter(WorkspaceConverter);

    const documentSnapshot = await getDoc(documentRef);
    if (!documentSnapshot.exists()) {
      throw new Error(`Workspace with slug ${workspaceId} was not found`);
    }

    const members = documentSnapshot.data().members;
    if (member.id in members) {
      throw new Error(
        `Member with ID ${member.id} already exists in the workspace. Role escalation or demotion is not supported via this method.`,
      );
    }

    members[member.id] = { ...member };

    await setDoc(documentRef, { members }, { merge: true });
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

/**
 * Creates a callback that adds a member to the workspace.
 * @param workspaceId - The workspace ID.
 * @returns A callback with a promise that resolves with void or throws an error.
 */
export function useAddMemberToWorkspace(workspaceId: string) {
  return useCallback(
    async (member: WorkspaceMemberDto) => {
      return addMemberToWorkspace(workspaceId, member);
    },
    [workspaceId],
  );
}

/**
 * Creates a callback to update a logo of the workspace.
 * @param workspaceId - The workspace ID.
 * @returns A callback with a promise that resolves with void or throws an error.
 */
export function useUpdateWorkspaceLogo(workspaceId: string) {
  return useCallback(
    async (logo: string) => {
      return updateWorkspace(
        workspaceId,
        new WorkspaceUpdateDto({ logo }),
        true,
      );
    },
    [workspaceId],
  );
}

/**
 * Retrieves or creates a workspace for the user.
 * If the user is not a member of any workspace, a new workspace is created.
 * Internal use only, use `useEnsureWorkspace` instead.
 * @param user - The user object.
 * @param workspaceId - The desired workspace ID, leave empty if it doesn't matter.
 * @returns A promise that resolves with the workspace object.
 * @throws `Error` if Firestore returns with an error.
 * @internal
 * @see useEnsureWorkspace
 */
async function ensureWorkspace(
  user: User | FirebaseUser,
  workspaceId?: string,
): Promise<WorkspaceDto> {
  trace({
    message: 'Retrieving or creating a workspace for the user',
    level: 'info',
  });

  try {
    // TODO Will need to delete this clause when we implement ACL and invitations.
    //  Currently everyone with access to the URL has access to the workspace,
    //  meaning we need to add the user to the workspace as a member.

    // Checking if the workspace with the provided ID exists
    if (workspaceId) {
      const docSnapshot = await getDoc(
        doc(
          collection(getFirestore(), getPathForWorkspaces()),
          workspaceId,
        ).withConverter(WorkspaceConverter),
      );

      // If the workspace exists, and the user is not a member, add them
      if (docSnapshot.exists()) {
        const isMember = user.uid.toString() in docSnapshot.data().members;
        if (!isMember) {
          trace({
            message: 'User is not a member of the workspace, adding them',
            level: 'info',
          });

          await addMemberToWorkspace(
            workspaceId,
            userToWorkspaceMember(user, WorkspaceMemberRole.Member),
          );
        }

        return docSnapshot.data();
      }
    }

    // If the workspace ID is not provided, or the workspace doesn't exist, find the first workspace the user is a member of
    const querySnapshot = await getDocs(
      query(
        collection(getFirestore(), getPathForWorkspaces()),
        where(`members.${user.uid}`, '!=', null),
      ).withConverter(WorkspaceConverter),
    );

    const firstDoc = querySnapshot.docs[0];
    let workspace = firstDoc?.data();

    // If the user is not a member of any workspace, create a new one
    if (!workspace) {
      trace({
        message: 'User is not a member of any workspace, creating a new one',
        level: 'info',
      });

      workspace = await createWorkspace(
        new WorkspaceCreateDto({
          name: user.displayName ?? generateGuestUserDisplayName(user.uid),
          terminology: WorkspaceTerminology.LearningAndDevelopment,
          slug: user.uid,
          members: {
            [user.uid]: userToWorkspaceMember(user, WorkspaceMemberRole.Admin),
          },
          tags: [FlowDefinitionTag.LearningAndDevelopment],
          createdBy: user.uid,
        }),
      );
    }

    return workspace;
  } catch (error) {
    trace(error as Error);
    throw error;
  }
}

/**
 * Creates a callback that ensures the user has a workspace.
 * Provide a workspace ID to ensure the user is a member of that workspace, if it exists.
 * If the workspace doesn't exist, or the workspace ID is not provided, and the user is not a member of any workspace, a new workspace is created.
 * @returns A callback with a promise that resolves with the workspace object or throws an error.
 */
export function useEnsureWorkspace() {
  const user = useUser();
  const workspaceId = useWorkspaceId();

  return useCallback(
    () => ensureWorkspace(user, workspaceId),
    [user, workspaceId],
  );
}
