import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import App from 'src/app/App';
import { LazyComponent } from 'src/app/pages/LazyComponent';
import { RedirectToFirstWorkspace } from 'src/app/pages/RedirectToFirstWorkspace';
import { AuthRouteGuard } from 'src/app/router/AuthRouteGuard';
import { Path } from 'src/app/router/routes';
import { WorkspaceAccessRouteGuard } from 'src/app/router/WorkspaceAccessRouteGuard';
import Error500 from '../pages/Error500';

/**
 * Router configuration that includes all the routes in the application.
 * Currently, it uses absolute paths using the {@link Path} enum, but the
 * routes are nested in a way that allows for relative paths as well.
 */
const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement: <Error500 />,
    children: [
      {
        path: Path.Home,
        element: (
          <AuthRouteGuard>
            <RedirectToFirstWorkspace />
          </AuthRouteGuard>
        ),
      },
      {
        path: Path.WorkspaceAuth,
        element: (
          <LazyComponent importFunc={() => import('../pages/Invited')} />
        ),
      },
      {
        path: Path.Workspace,
        element: (
          <AuthRouteGuard>
            <WorkspaceAccessRouteGuard>
              <Outlet />
            </WorkspaceAccessRouteGuard>
          </AuthRouteGuard>
        ),
        children: [
          {
            index: true,
            element: (
              <LazyComponent importFunc={() => import('../pages/Home')} />
            ),
          },
          {
            path: Path.WorkspaceRecentFlows,
            element: (
              <LazyComponent importFunc={() => import('../pages/Home')} />
            ),
          },
          {
            path: Path.Flow,
            element: (
              <LazyComponent importFunc={() => import('../pages/Flow')} />
            ),
            children: [
              {
                index: true,
                element: (
                  <LazyComponent
                    importFunc={() =>
                      import('../pages/Flow/overview/FlowOverviewPage')
                    }
                  />
                ),
              },
              {
                path: Path.FlowHistory,
                element: (
                  <LazyComponent
                    importFunc={() =>
                      import('../pages/Flow/history/FlowHistoryPage')
                    }
                  />
                ),
              },
            ],
          },
          {
            path: Path.FlowInstance,
            element: (
              <LazyComponent
                importFunc={() =>
                  import('../pages/FlowInstanceAndSessionHandler')
                }
              />
            ),
            children: [
              {
                index: true,
                element: (
                  <LazyComponent
                    importFunc={() =>
                      import('../pages/FlowInstance/FlowInstance')
                    }
                  />
                ),
              },
              {
                path: Path.Session,
                element: (
                  <LazyComponent
                    importFunc={() => import('../pages/Session')}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        path: Path.EmbeddedFlowInstance,
        element: (
          <LazyComponent
            importFunc={() =>
              import('../pages/HumanResources/CandidateLandingPage')
            }
          />
        ),
        children: [
          {
            index: true,
            element: (
              <LazyComponent
                importFunc={() =>
                  import('../pages/FlowInstance/FlowInstanceEmbedded')
                }
              />
            ),
          },
          {
            path: Path.EmbeddedSession,
            element: (
              <LazyComponent importFunc={() => import('../pages/Session')} />
            ),
          },
          {
            path: Path.EmbeddedSession,
            element: (
              <LazyComponent importFunc={() => import('../pages/Session')} />
            ),
          },
        ],
      },
      {
        path: Path.GuestSignup,
        element: (
          <LazyComponent importFunc={() => import('../pages/GuestSignup')} />
        ),
      },
      {
        path: Path.ActionHandlerAction,
        element: (
          <LazyComponent
            importFunc={() => import('../pages/EmailActionHandler')}
          />
        ),
      },
      {
        path: Path.FlowEditorHome,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditorHome/FlowEditorHome'
              )
            }
          />
        ),
      },
      {
        path: Path.FlowVersionEditor,
        element: (
          <LazyComponent
            importFunc={() =>
              import('../features/flowEditor/pages/FlowEditor/FlowEditor')
            }
          />
        ),
      },
      {
        path: Path.FlowVersionSessionBundles,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditor/SessionDrafts/SessionDrafts'
              )
            }
          />
        ),
      },
      {
        path: Path.FlowVersionModules,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditor/ModuleEditor/ModuleEditor'
              )
            }
          />
        ),
      },
      {
        path: Path.FlowVersionSettings,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/FlowEditor/FlowSettings/FlowSettings'
              )
            }
          />
        ),
      },
      {
        path: Path.SessionEditor,
        element: (
          <LazyComponent
            importFunc={() =>
              import('../features/flowEditor/pages/SessionEditor/SessionEditor')
            }
          />
        ),
      },
      {
        path: Path.SessionEditorPreview,
        element: (
          <LazyComponent
            importFunc={() =>
              import(
                '../features/flowEditor/pages/SessionPreview/SessionPreview'
              )
            }
          />
        ),
      },
      {
        path: Path.Admin,
        element: <LazyComponent importFunc={() => import('../pages/Admin')} />,
      },
      {
        path: Path.Auth,
        element: <LazyComponent importFunc={() => import('../pages/Auth')} />,
      },
      {
        path: Path.NotFound,
        element: (
          <LazyComponent importFunc={() => import('../pages/Error404')} />
        ),
      },
      {
        path: '*',
        element: (
          <LazyComponent importFunc={() => import('../pages/Error404')} />
        ),
      },
    ],
  },
]);

/**
 * The main router component that provides the router context to the application.
 */
export function Router() {
  return <RouterProvider router={router} />;
}
