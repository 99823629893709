import { PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { PathParams } from 'src/app/router/routeValidation';
import { Path, buildPath } from 'src/app/router/routes';
import {
  useFlowDefinitionId,
  useFlowInstanceId,
  useSessionId,
  useWorkspaceId,
} from 'src/utils/resource.hooks';

type TypedLinkProps<P extends Path> = LinkProps & {
  /** Valid path within the app. See `src/app/routes.ts` for valid paths. */
  to: P;
  /** A map of path param values. Allowed only if the path in the `to` prop includes params. */
  params?: PathParams;
  /** Whether to automatically fill in workspace and flow slugs if they are not provided. */
  autoFill?: boolean;
};

/**
 * Type-safe implementation of React Router's `<Link/>`,
 * which makes entering incorrect path params impossible.
 *
 * All props of the original `<Link/>` component are allowed.
 * @see `src/app/routes.ts` for valid paths.
 */
export function TypedLink<P extends Path>({
  to,
  children,
  params,
  title,
  autoFill = true,
  ...allProps
}: PropsWithChildren<TypedLinkProps<P>>) {
  const workspaceId = useWorkspaceId();
  const flowDefinitionId = useFlowDefinitionId();
  const flowInstanceId = useFlowInstanceId();
  const sessionId = useSessionId();

  params = params || {};
  if (autoFill) {
    params.workspaceId ??= workspaceId;
    params.flowDefinitionId ??= flowDefinitionId;
    params.flowInstanceId ??= flowInstanceId;
    params.sessionId ??= sessionId;
  }

  const url = buildPath(to, params);
  return (
    <Link {...allProps} to={url} title={title}>
      {children}
    </Link>
  );
}
