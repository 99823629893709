import { IsNumber, IsOptional } from 'class-validator';
import { LocaleMap } from 'src/@types/common';
import { NudgeTextDto } from './NudgeText.dto';
import { NudgeVideoDto } from './NudgeVideo.dto';

/**
 * Defines an activity in the session's facilitation process.
 *
 * The activity definition currently supports nudge text and video,
 * but can be freely extended later with e.g. outcome selection, toggling UI elements etc.
 */
export class SessionActivityDto {
  /**
   * Array index of the activity.
   * This might be required because Firestore doesn't guarantee array order.
   */
  @IsNumber()
  readonly order: number;

  /**
   * Optional text displayed in the nudge popover.
   */
  @IsOptional()
  readonly nudgeText?: LocaleMap<NudgeTextDto>;

  /**
   * Optional video for the activity.
   */
  @IsOptional()
  readonly nudgeVideo?: LocaleMap<NudgeVideoDto>;

  constructor(dto: SessionActivityDto) {
    this.order = dto.order;

    if (dto.nudgeText) {
      const textDto: LocaleMap<NudgeTextDto> = {};
      Object.entries(dto.nudgeText).forEach(([key, value]) => {
        textDto[key as keyof typeof textDto] = {
          isPublished: value.isPublished,
          ...new NudgeTextDto(value),
        };
      });
      this.nudgeText = textDto;
    }

    if (dto.nudgeVideo) {
      const videoDto: LocaleMap<NudgeVideoDto> = {};
      Object.entries(dto.nudgeVideo).forEach(([key, value]) => {
        videoDto[key as keyof typeof videoDto] = {
          isPublished: value.isPublished,
          ...new NudgeVideoDto(value),
        };
      });
      this.nudgeVideo = videoDto;
    }
  }
}
