import { defekt, Result, value } from 'defekt';
import { IDynamicFlowAdapter } from 'src/@types/flow';

type ImportedFlowAdapterModule = {
  default: IDynamicFlowAdapter;
};

class InvalidAdapterError extends defekt({
  code: 'InvalidFlowAdapter',
  defaultMessage:
    'The imported module does not adhere to the flow adapter contract.',
}) {}

/**
 * Dynamically imports and returns a flow adapter based on the flow ID argument.
 * @param flowPath - Existing path containing a valid flow
 * @returns The loaded flow adapter
 * @throws {Error  | InvalidAdapterError} Error instance if loading failed,
 * or an internal error code for other errors.
 */
export default async function loadAdapter(
  flowPath: string,
): Promise<Result<IDynamicFlowAdapter, Error | InvalidAdapterError>> {
  // Try dynamically importing the adapter
  // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
  const module: ImportedFlowAdapterModule = await import(
    `../../flows/${flowPath}/index.ts`
  );

  // Check if adapter is valid
  assertIsValidImport(module.default);

  // Success
  return value(module.default);
}

function assertIsValidImport(mod: unknown): asserts mod is IDynamicFlowAdapter {
  const {
    getCanvasComponent,
    getCanvasPlaceholder,
    getFlowConfig,
    getPreviewRenderer,
    getReadOnlyCanvasComponent,
  } = mod as IDynamicFlowAdapter;
  // TODO: also validate if function is a component constructor?
  // React.isValidElement() is deprecated.

  if (typeof getCanvasComponent !== 'function')
    throw new InvalidAdapterError('getCanvasComponent should be a function');
  if (typeof getCanvasPlaceholder !== 'function')
    throw new InvalidAdapterError('getCanvasPlaceholder should be a function');
  if (typeof getFlowConfig !== 'function')
    throw new InvalidAdapterError('getFlowConfig should be a function');
  if (typeof getPreviewRenderer !== 'function')
    throw new InvalidAdapterError('getPreviewRenderer should be a function');
  if (typeof getReadOnlyCanvasComponent !== 'function')
    throw new InvalidAdapterError(
      'getReadOnlyCanvasComponent should be a function',
    );
}
