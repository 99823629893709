import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { UnknownPartial } from '../../types';
import { _CanvasItemBaseDto } from './_canvasItemBase.dto';

/**
 * Contains a stateful canvas widget that has been instantiated on a canvas.
 * Such widgets can persist their unique state in the database, as if they were some editable content.
 */
export class CanvasWidgetDto<
  TContent = UnknownPartial,
> extends _CanvasItemBaseDto<TContent> {
  /**
   * AI evaluation will skip this node, and all of its content (including child nodes).
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsIgnoredOnEvaluate?: boolean;

  /**
   * Allows AI evaluation to use a custom prompt (instead of the prompt inherited from parent nodes)
   * for this node from the `aiCustomPrompt` field.
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsCustomPromptEnabled?: boolean;

  /**
   * Optional custom prompt for AI evaluation (instead of the prompt inherited from parent nodes).
   * This prompt is only considered if `aiCustomPromptEnabled` is `true`.
   * @default false
   */
  @IsOptional()
  @IsString()
  readonly aiCustomPrompt?: string;

  constructor(dto: CanvasWidgetDto<TContent>) {
    super(dto);
    this.aiCustomPrompt = dto.aiCustomPrompt;
    this.aiIsCustomPromptEnabled = dto.aiIsCustomPromptEnabled ?? false;
    this.aiIsIgnoredOnEvaluate = dto.aiIsIgnoredOnEvaluate ?? false;
  }
}
