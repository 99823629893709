import {
  IsArray,
  IsBoolean,
  IsNumber,
  IsOptional,
  IsString,
  ValidateNested,
} from 'class-validator';
import type { LocaleMap, TextObject, UUID } from 'src/@types/common';
import type { SessionType } from 'src/@types/session';
import { SessionIconId } from 'src/@types/session';
import {
  CanvasContent,
  isCanvasContent,
} from 'src/services/database/CanvasContent/utils';
import {
  IsFirestoreUUID,
  IsLocaleText,
} from 'src/services/database/validators/validators';
import { CanvasDefaultContentDto } from './canvasDefaultContent.dto';
import { CanvasWidgetConfigDto } from './canvasWidgetConfig.dto';
import { SessionActivityDto } from './sessionActivity.dto';
import { SurveyConfigDto } from './surveyConfig.dto';

/**
 * Describes a pre-defined element of the canvas that exists regardless of user activity.
 * The element can be anything, ranging from static text blocks to interactive drop zones.
 *
 * For now, the type is derived from {@link CanvasContent}, as these elements are defined
 * as regular canvas content in the flow editor too, except without some relational IDs
 * that must be present on content created by the user.
 *
 * TODO adopt PredefinedCanvasContent when introducing the flow editor
 */
export type CanvasWidget = Omit<
  CanvasContent,
  'flowInstanceId' | 'sessionId' | 'workspaceId'
>;

export class CanvasSessionDto {
  /** Unique identifier of the session definition */
  @IsFirestoreUUID()
  readonly id: UUID;

  /**
   * Each activity definition includes what happens in the session if that activity is active.
   *
   * For now, it only contains the optional nudge texts and nudge videos.
   */
  @IsArray()
  @ValidateNested({ each: true })
  readonly activities: SessionActivityDto[];

  /**
   * Canvas widgets (e.g. drop zones, tables, text blocks) are instantiated based on these config objects
   * at the moment the flow instance is created for every canvas.
   *
   * From that point, each canvas element will have its own live `CanvasContent` document
   * based on these initial settings.
   */
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  readonly canvasWidgetConfigs?: CanvasWidgetConfigDto[];

  /**
   * Any regular canvas content that should exist right away on the canvas.
   * These are instantiated based on these config objects at the moment
   * the flow instance is created for every canvas
   */
  @IsOptional()
  @IsArray()
  @ValidateNested({ each: true })
  readonly canvasDefaultItems?: CanvasDefaultContentDto[];

  /**
   * Whether the user can create outcomes that are displayed in the next session.
   * Outcomes can be canvas items, or e.g. strings entered in forms.
   *
   * TODO in the editor both outcome selection and the sidebar panel should be controlled by `activities`.
   * (Available sidebars may need their own property.)
   */
  @IsBoolean()
  readonly canHaveOutcomes: boolean;

  /**
   * Whether the user can import outcomes created in other sessions.
   * Outcomes can be canvas items, or e.g. strings entered in forms.
   * TODO in the editor the sidebar panel should be toggled by `activities`.
   * (Available sidebars may need their own property.)
   */
  @IsBoolean()
  readonly canHavePreviousOutcomes: boolean;

  /**
   * Intended for sessions created in the flow editor.
   * This array should contain drop zones and other pre-defined elements.
   *
   * Item IDs are included in the items as long as they are embedded in `CanvasSession`,
   * and are not separate database documents.
   *
   * @deprecated in favor of `canvasWidgetConfigs` prop
   */
  @IsOptional()
  @IsArray()
  readonly canvasItems?: CanvasWidget[];

  /** Describes which modal should be rendered when the user clicks the "Complete session" button */
  @IsOptional()
  @IsString()
  readonly completionModalId?: string;

  /**
   * The session's duration in minutes, as estimated by the flow author.
   *
   * This information is displayed to the user, indicating how long it takes
   * to complete the session after watching all videos and completing all activities.
   */
  @IsNumber()
  readonly duration: number;

  /** ID of the preset icon to render for the session in the flow overview. */
  @IsString()
  readonly icon: SessionIconId | string;

  /**
   * ID of the flow module which "contains" this session definition.
   *
   * At this point, modules are only used for visual grouping. In the database,
   * sessions are siblings of the flow modules, NOT their children.
   *
   * TODO try deprecating; seems unnecessary
   */
  @IsFirestoreUUID()
  readonly moduleId: UUID;

  /**
   * Describes session behavior and canvas background.
   * **Legacy** prop for hand-made canvas types.
   *
   * If its value is `"regular"`, then the UI should load the new drag-drop feature.
   * Otherwise, the flow adapter should be used for loading hand-made canvases
   * from hard-coded components.
   */
  @IsString()
  readonly sessionType: SessionType;

  /**
   * List of survey modal definitions to be triggered at the beginning or end of a session.
   * TODO make optional
   */
  @IsArray()
  @ValidateNested({ each: true })
  readonly surveys: SurveyConfigDto[];

  /**
   * Localized session title presented to the user.
   */
  @IsLocaleText()
  readonly title: LocaleMap<TextObject>;

  /** AI prompt for the session */
  @IsString()
  @IsOptional()
  readonly aiPrompt?: string;

  constructor(dto: CanvasSessionDto) {
    this.activities = dto.activities.map(
      (activity) => new SessionActivityDto(activity),
    );
    this.canHaveOutcomes = dto.canHaveOutcomes;
    this.canHavePreviousOutcomes = dto.canHavePreviousOutcomes;
    this.canvasItems = dto.canvasItems;
    this.canvasDefaultItems = dto.canvasDefaultItems?.map(
      (item) => new CanvasDefaultContentDto(item),
    );
    this.canvasWidgetConfigs = dto.canvasWidgetConfigs?.map(
      (item) => new CanvasWidgetConfigDto(item),
    );
    this.completionModalId = dto.completionModalId;
    this.duration = dto.duration;
    this.icon = dto.icon;
    this.id = dto.id;
    this.moduleId = dto.moduleId;
    this.sessionType = dto.sessionType;
    this.surveys = dto.surveys.map((survey) => new SurveyConfigDto(survey));
    this.title = dto.title;
    this.aiPrompt = dto.aiPrompt;

    // TODO [MO] Create validator for pre-defined canvas items
    this.canvasItems?.forEach((item) => {
      if (!isCanvasContent(item, { isPartialAllowed: true })) {
        throw new Error(`Invalid pre-defined canvas item`);
      }
    });
  }
}
