import {
  FirestoreDataConverter,
  PartialWithFieldValue,
  serverTimestamp,
  Timestamp,
} from '@firebase/firestore';
import { DragDropManager } from '@features/canvas/services/DragDropManager'; // circular import
import { UUID } from 'src/@types/common';
import { ensureNoUndefinedInFirestoreData } from 'src/services/database/utils';
import {
  isFirestoreTimestamp,
  isNumber,
  isObject,
  isString,
  isUUID,
  isValidType,
  validateArrayEvery,
  validateIfExists,
} from 'src/utils/typeAssertions/common';
import { AnyTimestamp, UnknownPartial } from '../types';

/**
 * Describes a piece of arbitrary canvas content, which may or may not have canvas coordinates.
 * @template Content - Describes the content's unique type.
 * @template TimestampType - Defaults to `number|null` for Unix timestamps,
 * in case a field's value is `serverTimestamp()`'s placeholder value.
 * Allows the Firestore data converter to alter the type of date-time fields.
 */
export type CanvasContent<
  Content = UnknownPartial,
  TimestampType = number | null,
> = Pick<Partial<DragDropManager.Drag.BasicData>, 'draggableType'> & {
  /** The item's unique ID (it should match the Firestore document ID). */
  id: UUID;
  /** ID of the flow instance where the canvas content was created. */
  flowInstanceId: UUID;
  /** ID of the canvas session where the canvas content was created. */
  sessionId: UUID;
  /**
   * Custom item content. Its format will depend on the component's use case.
   * E.g. a canvas note with custom background colors will have different props than a simple embedded date-picker.
   */
  content: Content;
  /**
   * Describes the type of data stored in the `content` prop.
   * This can be used e.g. to selectively run a type assertion function on the content value.
   */
  contentType?: string;
  /**
   * Time when the item was created.
   *
   * Its value may be `null`, until Firestore refreshes `serverTimestamp()`'s sentinel value.
   * When querying a document with `.data()`, consumers can opt for fallback values with the `serverTimestamps` option.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.SnapshotOptions#optional-servertimestamps
   *
   */
  createdAt: TimestampType;
  /** ID of user who created this content. */
  createdBy: UUID;
  /**
   * Time when the item was last edited by anyone.
   *
   * Its value may be `null`, until Firestore refreshes `serverTimestamp()`'s sentinel value.
   * When querying a document with `.data()`, consumers can opt for fallback values with the `serverTimestamps` option.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.SnapshotOptions#optional-servertimestamps
   */
  editedAt: TimestampType;
  /** Array of user IDs who have edited this content after its creation. (also includes the creator's user ID) */
  editedBy: UUID[];
  /**
   * `true` if the user has selected this canvas content as an outcome item of a session.
   *
   * This boolean is flipped simultaneously when the `SessionOutcome` item is created or deleted.
   * Likewise, when this canvas content is deleted, its associated outcome item is also deleted.
   */
  isSelectedOutcome?: boolean;
  /**
   * Time when any user last moved the item, if the content can be positioned.
   *
   * Its value may be `null`, until Firestore refreshes `serverTimestamp()`'s sentinel value.
   * When querying a document with `.data()`, consumers can opt for fallback values with the `serverTimestamps` option.
   * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.SnapshotOptions#optional-servertimestamps
   * */
  lastMovedAt?: TimestampType;
  /**
   * Could represent a drop zone ID, or any other parent entity.
   * @deprecated Use `parentNodes`
   */
  parentId?: UUID;
  /**
   * Ancestry tree of the draggable item that contains the IDs of all its parents,
   * starting from its immediate parent up to the root drop zone.
   *
   * Its main use case is so draggable children can hide the original component
   * not only when they are dragged, but also when their parent drop zone is dragged.
   */
  parentNodes?: UUID[];
  /**
   * Describes the type of data stored in the `content` prop.
   *
   * The `type` prop can be used e.g. to selectively run a type assertion function on the content value.
   * @deprecated Use `contentType` prop.
   */
  type: string;
  /** Item's X coordinate in `px`, if the content can be positioned. */
  x?: number;
  /** Item's Y coordinate in `px`, if the content can be positioned. */
  y?: number;
};

/**
 * Content document read from Firestore, where the datetime fields are still in Firestore's `Timestamp` format.
 */
type CanvasContentFromDb<Content = UnknownPartial> = CanvasContent<
  Content,
  Timestamp
>;

/**
 * Content intended to be written to Firestore, where date-time fields have been converted to either
 * - Firestore's `Timestamp` format, or
 * - the return value of `serverTimestamp()` for cases where `Date.now()` would be used.
 */
export type CanvasContentToDb<Content = UnknownPartial> = CanvasContent<
  Content,
  AnyTimestamp
>;

/**
 * Canvas content where timestamps can be either `number`s (as used by the UI),
 * or returned values of `serverTimestamp()`.
 *
 * This type makes it easier to edit entities in memory, because the create/edit/move time
 * can be set to `serverTimestamp()`, while the rest of the fields can stay untouched as numbers.
 *
 * The data converter takes care of conditionally converting numbers to timestamps.
 */
export type CanvasContentPayload<Content = UnknownPartial> = CanvasContent<
  Content,
  number | ReturnType<typeof serverTimestamp>
>;

export function isCanvasContent<Content = UnknownPartial>(
  value: unknown,
  options?: Parameters<typeof isValidType>[2],
): value is CanvasContent<Content> {
  const result = isValidType<CanvasContentFromDb<Content>>(
    value,
    [
      ['id', isUUID],
      ['createdAt', (val) => isFirestoreTimestamp(val) || isNumber(val)],
      ['createdBy', isUUID],
      ['editedAt', (val) => isFirestoreTimestamp(val) || isNumber(val)],
      [
        'lastMovedAt',
        validateIfExists((val) => isFirestoreTimestamp(val) || isNumber(val)),
      ],
      // TODO re-think ParentNodes vs UUID[] validation.
      // It's important whether assertion runs before or after converting toDb payload's ParentNodes to plain array.
      ['parentNodes', validateIfExists(validateArrayEvery(isUUID))],
      ['type', isString],
    ],
    options,
  );
  return result.isValid;
}

export function assertIsCanvasContent<Content = UnknownPartial>(
  value: unknown,
  options?: Parameters<typeof isValidType>[2],
): asserts value is CanvasContent<Content> {
  if (!isCanvasContent<Content>(value, options)) {
    throw new Error(
      `Value isn't type CanvasContentFromDb. (see previous error message)`,
    );
  }
}

function assertIsCanvasContentFromDb<Content = UnknownPartial>(
  value: unknown,
): asserts value is CanvasContentFromDb<Content> {
  const result = isValidType<CanvasContentFromDb>(
    value,
    [
      ['id', isUUID],
      ['createdAt', isFirestoreTimestamp],
      ['createdBy', isUUID],
      ['editedAt', isFirestoreTimestamp],
      ['lastMovedAt', isFirestoreTimestamp],
      ['parentNodes', validateIfExists(validateArrayEvery(isUUID))],
      ['type', isString],
    ],
    { isPartialAllowed: true },
  );
  if (!result.isValid) {
    throw new Error(
      `Value isn't type CanvasContentFromDb. (see previous error message)`,
    );
  }
}

/**
 * @template Content - Custom type definition for the canvas content.
 * @returns A typed data converter that should be passed to a document subscription
 * to properly convert data to/from Firestore.
 *
 * When used inside a hook, **memoize the return value** to avoid infinite re-renders.
 *
 * ### Timestamps
 *
 * The date-time fields of canvas content items are converted
 * between Unix timestamps (numbers) and Firestore `Timestamp`s.
 *
 * Timestamp fields of data coming from the database might contain `null`.
 * This is a placeholder value in cases when `serverTimestamp()` was used,
 * and the server is yet to return with the actual value.
 *
 * The UI should handle missing values, but has the option to query a previous value instead of `null`.
 * @example
 * ```ts
 * const item = doc(collection('collection/path', docId)).data({ serverTimestamps: 'estimate' })
 * ```
 * @see https://firebase.google.com/docs/reference/js/v8/firebase.firestore.SnapshotOptions#optional-servertimestamps
 */
export const CanvasContentConverter = <
  Content = UnknownPartial,
>(): FirestoreDataConverter<
  Partial<CanvasContent<Partial<Content>>>,
  Partial<CanvasContentToDb<Partial<Content>>>
> => ({
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    assertIsCanvasContentFromDb<Content>(data);
    const item: Partial<CanvasContent<Partial<Content>>> = {
      content: data.content,
      contentType: data.contentType,
      createdAt: data.createdAt?.toMillis(),
      createdBy: data.createdBy,
      draggableType: data.draggableType,
      editedAt: data.editedAt?.toMillis(),
      editedBy: data.editedBy,
      flowInstanceId: data.flowInstanceId,
      id: snapshot.id,
      isSelectedOutcome: data.isSelectedOutcome,
      parentNodes: data.parentNodes,
      sessionId: data.sessionId,
      type: data.type,
      x: data.x,
      y: data.y,
    };
    if (data.lastMovedAt) {
      item.lastMovedAt = data.lastMovedAt?.toMillis();
    }
    return item;
  },

  // TODO revisit this after TS or Firebase package upgrades
  // toFirestore()'s function overload messes up the otherwise valid return type
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  toFirestore(data, options) {
    // Cannot access params of SetOptions otherwise
    const isPartialData =
      isObject(options) && ('merge' in options || 'mergeFields' in options);
    assertIsCanvasContent<Content>(data, { isPartialAllowed: isPartialData });
    const partialData = data as Partial<typeof data>;
    // Type casting is necessary to allow
    // - copying data from the old object
    // - while converting from the old object's numbers to the new object's timestamps.
    // Immer wasn't helpful in this case either.
    const convertedContent: PartialWithFieldValue<CanvasContentToDb<Content>> =
      Object.assign(
        {},
        partialData as unknown as Partial<CanvasContentToDb<Content>>,
      );
    if (data.createdAt && typeof data.createdAt === 'number') {
      convertedContent.createdAt = Timestamp.fromMillis(data.createdAt);
    }
    if (data.editedAt && typeof data.editedAt === 'number') {
      convertedContent.editedAt = Timestamp.fromMillis(data.editedAt);
    }
    if (data.lastMovedAt && typeof data.lastMovedAt === 'number') {
      convertedContent.lastMovedAt = Timestamp.fromMillis(data.lastMovedAt);
    }

    return ensureNoUndefinedInFirestoreData(convertedContent);
  },
});
