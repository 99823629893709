import { IsBoolean, IsOptional, IsString } from 'class-validator';
import { UnknownPartial } from 'src/services/database/types';
import { _CanvasItemConfigBaseDto } from './_canvasItemBase.dto';

/**
 * Describes a pre-defined element of the canvas that exists regardless of user activity.
 * The element can be anything, ranging from static text blocks to interactive drop zones.
 *
 * For now, the type is derived from {@link CanvasContent}, as these elements are defined
 * as regular canvas content in the flow editor too, except without some relational IDs
 * that must be present on content created by the user.
 *
 * TODO replace flow editor's PredefinedCanvasContent with this
 */
export class CanvasWidgetConfigDto<
  TContent = UnknownPartial,
> extends _CanvasItemConfigBaseDto<TContent> {
  /**
   * AI evaluation will skip this node, and all of its content (including child nodes).
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsIgnoredOnEvaluate?: boolean;

  /**
   * Allows AI evaluation to use a custom prompt (instead of the prompt inherited from parent nodes)
   * for this node from the `aiCustomPrompt` field.
   * @default false
   */
  @IsOptional()
  @IsBoolean()
  readonly aiIsCustomPromptEnabled?: boolean;

  /**
   * Optional custom prompt for AI evaluation (instead of the prompt inherited from parent nodes).
   * This prompt is only considered if `aiCustomPromptEnabled` is `true`.
   * @default false
   */
  @IsOptional()
  @IsString()
  readonly aiCustomPrompt?: string;

  constructor(dto: CanvasWidgetConfigDto<TContent>) {
    super(dto);
    this.aiCustomPrompt = dto.aiCustomPrompt;
    this.aiIsCustomPromptEnabled = dto.aiIsCustomPromptEnabled ?? false;
    this.aiIsIgnoredOnEvaluate = dto.aiIsIgnoredOnEvaluate ?? false;
  }
}
