import { useMemo } from 'react';
import { LocaleMap, TextObject } from 'src/@types/common';

export enum Locale {
  EnUs = 'en_US',
}

/**
 * This is a placeholder for locale namespaces until i18n is implemented.
 */
export const DefaultLocale = Locale.EnUs;
export const MOCK_LOCALE = DefaultLocale;

type TextMap = LocaleMap<TextObject>;

/**
 * Returns the localized text for the given locale.
 * @param map - A map of locales to their respective text objects.
 * @param locale - The locale to get the text for.
 * @param fallback - A fallback text to use if the locale is not found in the map.
 */
export function getLocalized(
  map: TextMap | undefined,
  locale: Locale | undefined,
  fallback: string,
): string;
export function getLocalized(
  map?: TextMap,
  locale?: Locale,
  fallback?: string,
): string | undefined;
export function getLocalized(
  map?: TextMap,
  locale?: Locale,
  fallback?: string,
): string | undefined {
  return map?.[locale ?? DefaultLocale]?.text ?? fallback;
}

/**
 * Returns the current locale.
 * TODO This is a placeholder until i18n is implemented.
 */
export function useLocale() {
  return DefaultLocale;
}

/**
 * Returns the localized text for the current locale.
 * @param map - A map of locales to their respective text objects.
 * @param fallback - A fallback text to use if the current locale is not found in the map.
 */
export function useLocalized(
  map: TextMap | undefined,
  fallback: string,
): string;
export function useLocalized(
  map?: TextMap,
  fallback?: string,
): string | undefined;
export function useLocalized(map?: TextMap, fallback?: string) {
  const locale = useLocale();
  return useMemo(
    () => getLocalized(map, locale, fallback),
    [locale, map, fallback],
  );
}
